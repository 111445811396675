import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createInvitation } from '../../actions/invitations/actions';
import Select from 'react-select';
import Switch from 'react-switch';
import { IInvitation } from '../../store/invitations/types';
import { USER_TYPE } from '../../common/constants';
import { ISelectedCompany, IService } from '../../store/user/types';
import { dictionaryGet, stringTemplateReplace } from '../../common/functions';

type Props = {
  isModalOpen: boolean,
  toggleModal: Function,
  serviceCode: string,
  isEdit: boolean,
  editInvite: IInvitation,
  selectedService: IService,
  selectedCompany: ISelectedCompany,
  queryString: string;
};

export default function ModaleInviti(props: Props) {
  const dispatch = useDispatch();
  const selectedCompany = useSelector((state: AppState) => state.user.current.selectedCompany);
  const licenses = useSelector((state: AppState) => state.invitations.licenses);
  const [cognome, setCognome] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<number>>([0]);
  const [arraySelect, setArraySelect] = useState<Array<any>>([]);
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const [selectedValue, setSelectedValue] = useState<any>([{
    value: 0,
    label: <><p>{ dictionaryGet(dictionary, "global.console.invitations.no_solutions") }</p></>
  }]);
  const [isFirstNameError, setFirstNameError] = useState<boolean>(false);
  const [isLastNameError, setLastNameError] = useState<boolean>(false);
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [isSelectError, setIsSelectError] = useState<boolean>(false);


  useEffect(() => {
    if (!props.isEdit) {
      generateArraySelect();
    } else {
      populateEditFields();
    }
  }, [licenses]);

  useEffect(() => {
    if (!props.isEdit) {
      generateArraySelect();
    } else {
      populateEditFields();

    }
  }, []);

  useEffect(() => {
    if (props.isEdit) {
      populateEditFields();
    } else {
      generateArraySelect();
    }
  }, [props.isEdit]);

  const populateEditFields = () => {
    setCognome(props.editInvite.lastName);
    setNome(props.editInvite.firstName);
    setEmail(props.editInvite.email);
    if (props.editInvite.userType === USER_TYPE.ADMINISTRATOR) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    let tempArray: any[] = [];
    if (licenses.data !== null) {
      tempArray.length = licenses.data.length;
      tempArray.fill({
        visibility: false,
        id: 0,
        selectedValue: {
          value: 0,
          label: <><p>{ dictionaryGet(dictionary, "global.console.invitations.no_solutions") }</p></>
        }
      });
    }
    props.editInvite.license.map((lice, i) => {
      if (licenses.data !== null) {
        let currentLicence = licenses.data.filter((tempLice) => { return tempLice.id === lice.id; })[0];
        if (currentLicence != undefined && currentLicence.totalLicences >= 1) {
          tempArray[i] = {
            visibility: true,
            id: lice.id,
            selectedValue: {
              value: lice.id,
              label: <><p>{lice.name}</p><p style={{ color: 'rgb(119, 188, 92)' }}>{currentLicence.tempBalance}</p></>
            }
          };
        }
      }
    });
    setArraySelect(tempArray);
  };

  const generateArraySelect = () => {
    let arraySelectAppo: any = [];
    if (licenses.data !== null) {
      licenses.data.map((lice, k) => {
        arraySelectAppo.push({
          visibility: k === 0,
          id: 0,
          selectedValue: {
            value: 0,
            label: <><p>{ dictionaryGet(dictionary, "global.console.invitations.no_solutions") }</p></>
          }
        });
      });
    }

    setArraySelect(arraySelectAppo);
  };

  const removeSoluzione = (i: number) => {
    let removedProducts = [...products];
    removedProducts[i] = 0;

    let array = [...arraySelect];
    array[i].visibility = false;
    array[i].id = 0;
    array[i].selectedValue = {
      value: 0,
      label: <><p>{ dictionaryGet(dictionary, "global.console.invitations.no_solutions") }</p></>
    };

    setProducts(removedProducts);
    setArraySelect(array);
  };

  const handleSelectChange = (e: any, i: number) => {
    setIsSelectError(false);
    let tempArray: Array<any> = [...arraySelect];
    tempArray[i].visibility = true;
    tempArray[i].id = e.value;
    tempArray[i].selectedValue = e;

    setArraySelect(tempArray);
  };

  const selectSoluzioni = () => {
    let selectOptions: any[] = [];
    if (licenses.data !== null) {
      licenses.data.map((lice) => {
        if (lice.totalLicences >= 1 && (arraySelect.findIndex((r) => { return r.id === lice.id; }) == -1)) {
          selectOptions.push({
            value: lice.id,
            label: <><p>{lice.name}</p> <p
              style={{ color: 'rgb(119, 188, 92)' }}>{lice.tempBalance}</p></>
          });
        }
      });
    };

    const Option = (props: any) => {
      return <div onClick={() => {
        props.innerProps.onClick();
      }} style={{
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "0 20px",
        height: "30px"
      }}>{props.label}</div>;
    };

    const customStyles = {
      container: (provided: any, state: any) => {
        let width = "500px";
        let display = "inline-flex";
        let lineHeight = '30px';
        return { ...provided, width, display, lineHeight };
      },
      control: (provided: any, state: any) => {
        const width = "500px";
        const display = "inline-flex";
        const borderColor = isSelectError ? "red" : "hsl(0,0%,70%)";
        return { ...provided, width, display, borderColor };
      },
      singleValue: () => ({
        display: "inline-flex",
        justifyContent: "space-between",
        padding: "0px 20px",
        height: "30px",
        width: "99%",
        lineHeight: "30px"
      })
    };

    return arraySelect.map((r, i) => {
      if (r.visibility) {
        return (
          <React.Fragment key={i}>
            <Select value={r.selectedValue} isClearable={false} isSearchable={false} isMulti={false}
              style={{ margin: "0", width: "calc(100 % - 84px)" }} styles={customStyles} options={selectOptions}
              components={{ Option }} key={'select' + i}
              onChange={(e: any) => {
                handleSelectChange(e, i);
              }} />
            {
              arraySelect.filter((r) => { return r.visibility == true; }).length > 1
                ?
                <Button type="button" onClick={() => { removeSoluzione(i); }}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
                : ''}
          </React.Fragment>
        );
      }
    });
  };

  const renderForm = () => {
    return (
      <Form className="form-inviti">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="nome" className="label-form-inviti">{ dictionaryGet(dictionary, "global.console.invitations.first_name") }:</Label>
              <p>
                <Input placeholder={ dictionaryGet(dictionary, "global.console.invitations.first_name") } name="nome" id="nome"
                  className={isFirstNameError ? "input-form error" : "input-form"} value={nome} onChange={(e) => {
                    setFirstNameError(false);
                    setNome(e.target.value);
                  }} />
                {isFirstNameError ? <span className="error-message">{ dictionaryGet(dictionary, "global.console.invitations.insert") + " " + dictionaryGet(dictionary, "global.console.invitations.first_name") }</span> : ''}
              </p>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="cognome" className="label-form-inviti">{ dictionaryGet(dictionary, "global.console.invitations.last_name") }:</Label>
              <p>
                <Input placeholder={ dictionaryGet(dictionary, "global.console.invitations.last_name") } name="cognome" id="cognome"
                  className={isLastNameError ? "input-form error" : "input-form"} value={cognome} onChange={(e) => {
                    setLastNameError(false); setCognome(e.target.value);
                  }} />
                {isLastNameError ? <span className="error-message right">{ dictionaryGet(dictionary, "global.console.invitations.insert") + " " + dictionaryGet(dictionary, "global.console.invitations.first_name") }</span> : ''}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="email" className="label-form-inviti">E-mail:</Label>
              <Input placeholder={ dictionaryGet(dictionary, "global.console.invitations.insert") + " " + "e-mail"} name="email" id="email"
                className={isEmailError ? "input-form long error" : "input-form long"} value={email}
                onChange={(e) => { setEmailError(false); setEmail(e.target.value); }} />
              {isEmailError ? <span className="error-message">{ dictionaryGet(dictionary, "global.console.invitations.insert") + " e-mail" }</span> : ''}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Switch
                className="switch-component"
                style={{ marginRight: '10px' }}
                height={22}
                width={48}
                onColor={'#415ca3'}
                checked={isAdmin}
                onChange={() => { setIsAdmin(!isAdmin); }} />
              <span className="label-form-inviti" style={{ marginLeft: "15px" }}>{isAdmin ? dictionaryGet(dictionary, "global.console.invitations.administrator") : dictionaryGet(dictionary, "global.console.invitations.not_administrator") }</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="email" className="label-form-inviti">{ dictionaryGet(dictionary, "global.console.invitations.solutions") }:</Label>
              <div>{selectSoluzioni()}</div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  const chiudiModale = () => {
    clearState();
    props.toggleModal(false);
  };

  const clearState = () => {
    setNome('');
    setCognome('');
    setEmail('');
    setIsAdmin(false);
    setEmailError(false);
    setFirstNameError(false);
    setLastNameError(false);
    setProducts([0]);
    setArraySelect([]);
    setSelectedValue([{ value: 0, label: <><p>{ dictionaryGet(dictionary, "global.console.invitations.no_solutions") }</p></> }]);
  };

  const submitForm = (param: boolean) => {
    let isError: boolean = false;

    if (cognome.length <= 0) {
      isError = true;
      setLastNameError(true);
    }

    if (nome.length <= 0) {
      isError = true;
      setFirstNameError(true);
    }

    if (email.length <= 0) {
      isError = true;
      setEmailError(true);
    }

    let licensesIds = arraySelect.filter((r => { return r.visibility == true && r.id != 0; })).map((r) => r.id);

    if (licensesIds.length < 1) {
      isError = true;
      setIsSelectError(true);
    }

    if (isError == false) {
      let payload = {
        "companyId": 0,
        "email": "string",
        "firstName": "string",
        "lastName": "string",
        "licenseIds": [0],
        "serviceCode": "string",
        "userType": "USER"
      };

      if (selectedCompany !== null && licenses.data !== null) {
        payload.companyId = selectedCompany.company.id;
        payload.email = email.trim();
        payload.firstName = nome;
        payload.lastName = cognome;
        payload.serviceCode = props.serviceCode;
        payload.licenseIds = licensesIds;
        payload.userType = isAdmin ? 'ADMINISTRATOR' : 'USER';
      }
      dispatch(createInvitation(payload, props.selectedService.id, props.selectedCompany.company.id, 1, props.queryString));
      if (param) {
        chiudiModale();
      }
    }
  };

  const submitAndClear = () => {
    submitForm(false);
    clearState();
    generateArraySelect();
  };

  const renderModalFooter = () => {
    if (props.isEdit === true) {
      return (
        <ModalFooter>
          <Button className="btn-form-secondary" onClick={() => {
            chiudiModale();
          }}>{ dictionaryGet(dictionary, "global.console.cancel") }</Button>
          <Button disabled={true} className="btn-form-primary" color="primary" onClick={() => {
            submitForm(true);
          }}>{ dictionaryGet(dictionary, "global.console.save") }</Button>
        </ModalFooter>
      );
    } else {
      return (
        <ModalFooter>
          <Button className="btn-form-secondary" onClick={() => {
            chiudiModale();
          }}>{ dictionaryGet(dictionary, "global.console.cancel") }</Button>
          <Button className="btn-form-primary" color="primary" onClick={() => {
            submitForm(true);
          }}>{ dictionaryGet(dictionary, "global.console.save") }</Button>
          <Button className="btn-form-primary" color="primary" onClick={() => {
            submitAndClear();
          }}>{ dictionaryGet(dictionary, "global.console.invitations.save_create") }</Button>
        </ModalFooter>
      );
    }
  };

  const aggiungiSoluzione = () => {
    if (licenses.data !== null) {
      let idx = arraySelect.findIndex((i) => { return i.visibility === false; });
      let tempArray: Array<any> = [...arraySelect];
      tempArray[idx].visibility = true;
      tempArray[idx].id = 0;
      setArraySelect(tempArray);
    }
  };

  const creaInvitoAzienda = (comp:any) => {
    let lab = dictionaryGet(dictionary, "global.console.invitations.create_description");
    let key = { "companyName" : comp.company.name };
    lab = stringTemplateReplace(lab, key);
    return lab;
  };

  if (selectedCompany !== null && licenses.data !== null) {
    return (
      <Modal isOpen={props.isModalOpen} toggle={() => {
        chiudiModale();
      }} size='md' style={{ width: 'auto', height: 'auto', maxWidth: '40vw' }}>
        <ModalHeader toggle={() => { chiudiModale(); }}>{ dictionaryGet(dictionary, "global.console.invitations.new_invitation") }</ModalHeader>
        <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
          <Row style={{ marginBottom: '25px' }}>
            <Col md="12"><span>{ creaInvitoAzienda(selectedCompany) }</span></Col>
          </Row>
          {renderForm()}
          <Row style={{ marginTop: '25px', textAlign: "center" }}>
            <Col md="2" style={{ maxWidth: "80px" }}></Col>
            <Col md="9">
              <Button className="btn-form-aggiungi" disabled={arraySelect.length > 1 ? arraySelect.findIndex((i) => { return i.visibility === false; }) != -1 ? false : true : true}
                onClick={() => { aggiungiSoluzione(); }} id="aggiungi">+ { dictionaryGet(dictionary, "global.console.invitations.add_solution") }</Button>
            </Col>
            <Col md="1"></Col>
          </Row>
        </ModalBody>
        {renderModalFooter()}
      </Modal>
    );
  } else {
    return null;
  }
}
